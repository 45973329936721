import Axios from 'axios';

const options = {
    baseURL: 'https://odysee-app-prod.herokuapp.com',
    timeout: 5000,
    headers: {
        common: {
            'Content-Type': 'application/json',
        },
    },
};

const axios = Axios.create(options);

const get = url => axios.get(url);

const put = (url, payload) => axios.put(url, payload);

const post = (url, payload) => axios.post(url, payload);

const del = (url, payload) => axios.delete(url, payload);

const updateHeaders = (update = {}) => {
    Object.assign(axios.defaults.headers.common, update);
};

const addAuthorization = token => {
    axios.defaults.headers.common.Authorization = token;
};

const removeAuthorization = () => {
    delete axios.defaults.headers.common.Authorization;
};

export {
    axios,
    get,
    put,
    post,
    del,
    updateHeaders,
    addAuthorization,
    removeAuthorization,
};

export default axios;
