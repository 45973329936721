import React, { useState } from 'react';

import Layout from '../components/layout';
import './reset-password.scss';
import api from '../api';
import queryString from 'query-string';

const ResetPassword = ({ location }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(null);

    const token = queryString.parse(location.search).token;

    const sendPayload = async () => {
        try {
            if (password !== confirmPassword) {
                setError('Passwords do not match')

                return;
            }

            if (!/^.{8,}$/.test(password)) {
                setError('Must be a greater than 8 characters')

                return;
            }

            await api.put('/auth/password/reset', {
                password,
                reset_token: token
            });

            setUpdated(true)
        } catch (error) {
            setUpdated(false)
            setError('Something went wrong please contact the odysee to notify them of this issue at jorta@odysee.io')
        }
    };

    return (
        <Layout>
            {updated ? (
                <h1 className='updated'>Your password has been reset</h1>
            ) : (
                <div className="form">
                    <h1>Reset your password</h1>
                    <div className="input">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setError(null)
                            }}
                        />
                    </div>
                    <div className="input">
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value)
                                setError(null)
                            }}
                        />
                    </div>
                    <button onClick={() => sendPayload()}>Submit</button>
                    {error ? <p className='error'>{error}</p> : null}
                </div>
            )}
        </Layout>
    );
};

export default ResetPassword;
