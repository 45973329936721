import React from 'react'
import { Link } from "gatsby"

import './footer.scss'

const Footer = props => {
    return (
        <footer>
            <ul>
                <li className='title'>Odysee</li>
                <li><a href="mailto:info@odysee.io">Contact</a></li>
                <li>
                    <Link
                        to="/privacy-policy"
                    >
                        Privacy Policy
                    </Link>
                </li>
                <li>
                    <Link
                        to="/terms-and-conditions"
                    >
                        Terms and Conditions
                    </Link>
                </li>
            </ul>
            <span>© {new Date().getFullYear()} Odysee</span>
        </footer>
    )
}


export default Footer
